<template>
   <div id="main-wrapper">
     <!-- <Container/> -->
     <div class="position-relative">
       <section class="blockElement">
         <div class="container privacyPolicy">
           <h1 class="mb-4">{{contentlist.risk_warning_content1}}</h1>
           <p>{{contentlist.risk_warning_content2}}
             <router-link :to="entityPath+'/'" class="secondarycolor">{{contentlist.capital_wallet_content}}</router-link> {{contentlist.risk_warning_content4}}
           </p>
           <p>{{contentlist.risk_warning_content5}}</p>
           <h5>{{contentlist.risk_warning_content6}}</h5>
           <p>{{contentlist.risk_warning_content7}}</p>
           <h5>{{contentlist.risk_warning_content8}}</h5>
           <p>{{contentlist.risk_warning_content9}}</p>
           <p>{{contentlist.risk_warning_content10}}</p>
           <p>{{contentlist.risk_warning_content11}}</p>
           <p>{{contentlist.risk_warning_content12}}</p>
           <h5>{{contentlist.risk_warning_content13}}</h5>
           <p>{{contentlist.risk_warning_content14}}</p>
           <p>{{contentlist.risk_warning_content15}}</p>
           <p>{{contentlist.risk_warning_content16}}</p>
           <h5>{{contentlist.risk_warning_content17}}</h5>
           <p>{{contentlist.risk_warning_content18}}</p>
           <h5>{{contentlist.risk_warning_content19}}</h5>
           <p>{{contentlist.risk_warning_content20}}</p>
           <h5>{{contentlist.risk_warning_content21}}</h5>
           <p>{{contentlist.risk_warning_content22}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.risk_warning_content23}}</router-link>{{contentlist.risk_warning_content24}}</p>
           <h5>{{contentlist.risk_warning_content25}}</h5>
           <p>{{contentlist.risk_warning_content26}}</p>
           <h5>{{contentlist.risk_warning_content27}}</h5>
           <p>{{contentlist.risk_warning_content28}}</p>
           <h5>{{contentlist.risk_warning_content29}}</h5>
           <p>{{contentlist.risk_warning_content30}} <router-link :to="entityPath+'/security'" class="secondarycolor">{{contentlist.risk_warning_content31}}</router-link> {{contentlist.risk_warning_content32}}</p>
           <h5>{{contentlist.risk_warning_content33}}</h5>
           <p>{{contentlist.risk_warning_content34}}</p>
           <p>{{contentlist.risk_warning_content35}}</p>
           <p>{{contentlist.risk_warning_content36}}</p>
           <p>{{contentlist.risk_warning_content37}}</p>
           <p>{{contentlist.risk_warning_content38}}</p>
           <p>{{contentlist.risk_warning_content39}}</p>
           <ul>
             <li>{{contentlist.risk_warning_content39_list1}}</li>
             <li>{{contentlist.risk_warning_content39_list2}}</li>
             <li>{{contentlist.risk_warning_content39_list3}}</li>
             <li>{{contentlist.risk_warning_content39_list4}}</li>
             <li>{{contentlist.risk_warning_content39_list5}}</li>
             <li>{{contentlist.risk_warning_content39_list6}}</li>
             <li>{{contentlist.risk_warning_content39_list7}}</li>
             <li>{{contentlist.risk_warning_content39_list8}}</li>
             <li>{{contentlist.risk_warning_content39_list9}}</li>
             <li>{{contentlist.risk_warning_content39_list10}}</li>
             <li>{{contentlist.risk_warning_content39_list11}}</li>
             <li>{{contentlist.risk_warning_content39_list12}}</li>
           </ul>
           <h5>{{contentlist.risk_warning_content40}}</h5>
           <p>{{contentlist.risk_warning_content41}}</p>
           <h5>{{contentlist.risk_warning_content42}}</h5>
           <p>{{contentlist.risk_warning_content43}}</p>
           <h5>{{contentlist.risk_warning_content44}}</h5>
           <p>{{contentlist.risk_warning_content45}}</p>
         </div>
       </section>
     </div>
     <!-- <Footer /> -->
     <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
       <div class="container">
         <div class="row justify-content-center">
           <div class="col-12 text-center primarybgSection">
             <div class="criptoIcon">
               <span class="left-tp one">
                 <img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid">
               </span>
               <span class="left-tp tow">
                 <img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid">
               </span>
               <span class="left-tp three">
                 <img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid">
               </span>
               <span class="left-tp four">
                 <img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid">
               </span>
             </div>
             <div class="content position-relative">
               <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
               <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
               <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
             </div>
           </div>
         </div>
       </div>
     </section>
   </div>
 </template>
 <script>
   import {
     commonAllmixins
   } from '@/plugins/commonAll' //anamica
   export default ({
     mixins: [commonAllmixins], //anamica
     data() {
       return {
         tab: 1,
       };
     },
   });
 </script>